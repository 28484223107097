/*------------- #WIDGETS --------------*/


.widget {
  margin-bottom: 80px;
}

form.w-search {
	position: relative;

	button.icon {
	  position: absolute;
	  right: 30px;
	  top: 15px;
	  font-size: 26px;
	  color: #e1e1e1;
	  background: transparent;
	}
}

.w-post-category {

  .heading {
	margin-bottom: 40px;
  }

  .post-category-wrap {
	.category-post-item {
	  color: $font-color;
	  margin-bottom: 30px;

	  .post-count {
		font-size: 14px;
		margin-right: 30px;
		transition: all .3s ease;
		width: 20px;
		display: inline-block;
		float: left;
		clear: both;
		position: relative;
		top: 2px;
	  }

	  a {
		font-size: 16px;
		color: $font-color;
		transition: all .3s ease;
		position: relative;
		display: block;
		overflow: hidden;

		i {
		  margin-left: 5px;
		  opacity: 0;
		  transition: all .3s ease;
		  color: $primary-color;
		  position: relative;
		  font-size: 12px;
		}
	  }

	  &:hover {
		.post-count {
		  color: $heading-color;
		}

		a {
		 color: $primary-color;

		  i {
			opacity: 1;
		  }
		}
	  }
	}
  }
}

.w-about {
  .heading {
	.heading-title {
	  line-height: 1.2;
	  margin-bottom: 5px;
	}

	.heading-line {
	  margin-bottom: 25px;
	}

	p {
	  margin-bottom: 30px;
	  line-height: 1.4;
	}
  }
}

.w-request {
  padding: 60px 30px;
  border-radius: 10px;
  border-color: transparent;
  text-align: center;

  .w-request-content {
	img {
	  margin-bottom: 30px;
	}

	.w-request-content-title {
	  margin-bottom: 20px;

	  @media (max-width: 1024px) {
		font-size: 22px;
	  }
	}

	.w-request-content-text {
	  color: $white-color;
	  font-size: 16px;
	  margin-bottom: 30px;
	}
  }
}

.w-latest-news {
	.heading {

	}

  .latest-news-wrap {
	margin-bottom: 30px;

	.latest-news-item {
	  padding: 20px 0;
	  border-bottom: 2px solid $border-color;

	  &:last-child {
		border-bottom: none;
	  }

	  .post-additional-info {
		margin-bottom: 20px;

		.post__date {
		  padding: 0;
		  float: none;
		  margin-right: 30px;
		  display: inline-block;
		  margin-bottom: 0;

		  i {
			font-size: 20px;
			margin-right: 10px;
			transition: all .3s ease;
		  }
		}
	  }

	  .post__title {
		font-size: 20px;

		&:hover {
		  color: $primary-color;
		}
	  }
	}
  }
}

.w-follow {
  .heading {
	margin-bottom: 40px;
  }

  .w-follow-wrap {
	.w-follow-item {
	  padding: 12px 15px 12px 40px;
	  display: inline-block;
	  min-height: 60px;
	  width: 100%;
	  position: relative;

	  @media (max-width: 1024px) {
		padding: 15px;
	  }

	 .w-follow-social__item {
	   margin-right: 20px;
	   display: inline-block;

	   i {
		 color: $white-color;
		 font-size: 30px;
	   }
	 }

	  .w-follow-title {
		display: inline-block;
		color: #fff;
		background-color: inherit;

		.w-follow-add {
		  display: inline-block;
		  width: 60px;
		  height: 100%;
		  line-height: 60px;
		  text-align: center;
		  position: absolute;
		  right: 0;
		  top: 0;
		  background-color: rgba(0, 0, 0, .1);

		  i {
			font-size: 15px;
			color: $white-color;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);

			&.plus {
				opacity: 1;
			}

			&.check {
			  opacity: 0;
			}
		  }

		  &.active {
			i {
			  &.plus {
				opacity: 0;
			  }
			  &.check {
				opacity: 1;
			  }
			}
		  }
		}
	  }
	}
  }
}

.w-tags {
  overflow: hidden;
  .heading {
	margin-bottom: 40px;
  }

  .tags-wrap {
	a {
	  padding: 10px 15px;
	  font-size: 15px;
	  color: $font-color;
	  border: 2px solid #dddede;
	  border-radius: 50px;
	  margin-right: 10px;
	  margin-bottom: 10px;
	  float: left;
	  transition: all .3s ease;

	  @media (max-width: 1024px) {
		padding: 10px 20px;
	  }

	  &:hover {
		background-color: $primary-color;
		color: $white-color;
		border-color: $primary-color;
	  }
	}
  }
}

.sidebar .widget {
  &:first-child {
	@media (max-width: 768px) {
	  margin-top: 40px;
	}
  }
}

.widget.contacts .contacts-icon{
  max-width: 48px;
  max-height: 48px;
}

.visual-hidden{
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}